import { IModel } from "@/@types/models/model";
import { coreFields } from "./helpers/coreFields";
import { commonFields } from "./helpers/commonFields";

const Morski_bentos: IModel = {
  title: "Morski bentos",
  apiPath: "data/morski_bentos",
  forms: {
    "default": {
        fields: ["fid","nks_kod","nks_ime"]
    }
  },
  listViews: {
    "default": {
        fields: ["fid","nks_kod","nks_ime"]
    }
  },
  fields: [
    {
        title: "ID",
        source: "fid",
        ttoken: "common.id",
        type: "numeric",
    },
    {
        title: "NKS kod",
        source: "nks_kod",
        ttoken: "stanista.nks_kod",
        type: "numeric",
    },
    {
        title: "NKS naziv",
        source: "nks_ime",
        ttoken: "stanista.nks_ime",
        type: "text",
    },
  ]
}
export default Morski_bentos;