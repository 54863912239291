
import React, { Fragment, useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import clsx from "clsx";

//Material-UI Core Components
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionActions from "@mui/material/AccordionActions";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

//Material-UI Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AssessmentIcon from "@mui/icons-material/Assessment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EventIcon from "@mui/icons-material/Event";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { Panorama } from "@mui/icons-material";

//Custom Components
import MapCard from "@/ui/MapCard/MapCard";
import MapCardHeader from "@/ui/MapCard/MapCardHeader";
import MapCardAvatar from "@/ui/MapCard/MapCardAvatar";
import MapCardContent from "@/ui/MapCard/MapCardContent";
import MapCardActions from "@/ui/MapCard/MapCardActions";
import MapCardActionButton from "@/ui/MapCard/MapCardActionButton";
import { formats } from "@/lib/formats";
import dataController from "@/lib/dataController";
import MapCardHeaderButtonClose from "@/ui/MapCard/MapCardHeaderButtonClose";
import DialogContext from "@/ui/DialogContext/DialogContext";
import { GridContainer, GridItem } from "@/ui/Grid";
import ModelTable from "@/ui/Table/ModelTable";
// import { dataService } from "@/services/dataService";
import FormContent from "@/components/FormContent";
import UserContext from "@/components/UserContext/UserContext";

// import modelPregled from "@/models/pregledi";
// import ImageSlider from "@/components/ImageSlider";

import modelData from "@/models/data";
import modelObjekti from "@/models/objekti";
import modelDkp from "@/models/dkp";
import modelVlasnici from "@/models/vlasnici";
import modelPosjednici from "@/models/posjednici";
import modelKablovi from "@/models/kablovi";
import modelPaceracija from "@/models/parcelacija";
import modelSolari from "@/models/solari";
import modelTrafostanice from "@/models/trafostanice";
import modelUporaba from "@/models/uporaba";
import modelZone from "@/models/zone";
import modelPaneli from "@/models/paneli";
import modelKopnena from "@/models/kopnena_stanista";
import modelBentos from "@/models/morski_bentos";

// import DataDialog from "@/views/MapPage/DataDialog";
// import InspectionDialog from "@/views/MapPage/InspectionDialog";

//Types
import { DCRecord } from "@/@types/lib/dataController";
import { GFIFeatureType } from "@/services/gsService";
import { CardOverlayType } from "@/@types/views/GIS/map";

export type CardPosition = [number, number]

export interface ICardOverlayData {
  position: CardPosition
  feature: GFIFeatureType
  type: CardOverlayType
  record?: DCRecord
}
export interface GSInfoCardProps {
  featureData: ICardOverlayData,
  onClose: any,
  // expanded: boolean,
  // setExpanded: React.Dispatch<React.SetStateAction<boolean>>
  expandedVlasnici: boolean;
  setExpandedVlasnici: React.Dispatch<React.SetStateAction<boolean>>;
  expandedPosjednici: boolean;
  setExpandedPosjednici: React.Dispatch<React.SetStateAction<boolean>>;
}

function GSInfoCard(props: GSInfoCardProps) {
  const { t } = useTranslation();

  const initialRecod = props.featureData.record ? props.featureData.record : props.featureData.feature.properties;

  const [record, setRecord] = useState(initialRecod);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [loadStatus, setLoadStatus] = useState({ isLoading: true, isLoaded: false });
  const [imageLoadStatus, setImageLoadStatus] = useState({ imageLoading: true });
  
  const [recordsPosjednici, setRecordsPosjednici] = useState<DCRecord[]>([]);
  const [recordsVlasnici, setRecordsVlasnici] = useState<DCRecord[]>([]);

  const dialogContext = useContext(DialogContext);
  const userContext = useContext(UserContext);

  const { featureData, onClose, expandedVlasnici, setExpandedVlasnici, expandedPosjednici, setExpandedPosjednici } = props;

  const { type } = featureData;

  const hasVlasniciButton = type === "dkp" ? true : false;
  const hasPosjedniciButton = type === "dkp" ? true : false;
  const hasActions = hasVlasniciButton || hasPosjedniciButton;

  const getModel = () => {
    switch (type) {
      case "dkp":
        return modelDkp;
      case "kablovi":
        return modelKablovi
      case "prijedlog_parcelacije":
        return modelPaceracija;
      case "solari":
        return modelSolari;
      case "trafostanice":
        return modelTrafostanice;
      case "uporaba":
        return modelUporaba;
      case "zone":
        return modelZone;
      case "paneli":
        return modelPaneli;
      case "kopnena_stanista":
        return modelKopnena;
      case "morski_bentos":
        return modelBentos;
      default:
        return modelData;
    }
  };

  const model = getModel();

  const dc = new dataController(model);

  const dcPosjednici = new dataController(modelPosjednici);
  const dcVlasnici = new dataController(modelVlasnici);

  const faIconClass = "fas fa-info ";

  const recordId = record ? record.id : null;

  const handleRefresh = function () {
    setRefreshCounter(refreshCounter + 1);

    setLoadStatus({ isLoading: true, isLoaded: false });
  };

  // const handleDetailCollapse = function () {
  //  setExpanded(!expanded);
  // };

  const loadVlasnici = () => {
    if (record) {
      dcVlasnici.GetData(`data/dkp/${record.id}/zk-vlasnici`).then((resp) => {
        if (resp.success && Array.isArray(resp.data)) {
          setRecordsVlasnici(resp.data);
        }
      });
    }
  };
  const loadPosjednici = () => {
    if (record) {
      dcPosjednici.GetData(`data/dkp/${record.id}/posjednici`).then((resp) => {
        if (resp.success && Array.isArray(resp.data)) {
          setRecordsPosjednici(resp.data);
        }
      });
    }
  };

  const handleGoToPhotoCentric = () => {
    const url =
      location.origin +
      "/paneli/?id=" +
      recordId;
    window.open(url, "_blank"); // opens 3d in a new tab
  }

  useEffect(() => {
    if (recordId) {
      loadPosjednici();
      loadVlasnici();
    }
  }, [recordId]);


  const handleEdit = () => {
    // const model = getModel(type);
    // const dc = new dataController(model);
    // dialogContext.showDialog(DataDialog, {
    //   type: type,
    //   dc: dc,
    //   recordId: record.id,
    //   mode: "update",
    //   form: "edit",
    //   iconClass: faIconClass,
    //   onClose: (result) => handleDataDialogClose(result, record.id)
    // });
  };

  // const handleDataDialogClose = (dialogResult, recordId) => {
  //   if (dialogResult.dataChanged) {
  //     // dataService.getData(recordId).then((result) => {
  //     //   if (result.success) {
  //     //     setRecord(result.data);
  //     //   }
  //     // });
  //   }
  // };

  // const refreshInspections = () => {
  //   // dataService.getPreglediById(record.id).then((resp) => {
  //   //   if (resp) {
  //   //     setInspections(resp);
  //   //   } else {
  //   //     setInspections(null);
  //   //   }
  //   //   setLoadStatus({
  //   //     isLoading: false,
  //   //     isLoaded: true
  //   //   });
  //   // });
  // };

  // useEffect(() => {
  //   refreshInspections();
  // }, [record.id, refreshCounter]);

  useEffect(() => {
    if (featureData) {
      if (featureData.record) {
        setRecord(featureData.record);
      } else if (featureData.feature) {
        setRecord(featureData.feature.properties);
      }
    }
    setRecord;
  }, [featureData]);

  const form = "default";
  const title = record.naziv ? record.naziv : type ? t("map:layer." + type) : "Info";

  // const hasActions = ["dkp", "paneli"].includes(type);
  const allowMore = type === "dkp" ? true : false;

  const hasVlasnici = recordsVlasnici.length > 0;

  const handleTogglePosjednici = function () {
    const newValue = !expandedPosjednici;
    setExpandedPosjednici(newValue);
    if (newValue) {
      setExpandedVlasnici(false);
    }
  };

  const handleToggleVlasnici = function () {
    const newValue = !expandedVlasnici;
    setExpandedVlasnici(newValue);
    if (newValue) {
      setExpandedPosjednici(false);
    }
  };

  return (
    <Fragment>
      <MapCard
        sx={{
          backgroundColor: "background.default",
          minWidth: "500px"
        }}
      >
        <MapCardHeader
          title={title}
          subheader={t("common.id") + ": " + (record.id?record.id:record.fid?record.fid:undefined)}
          subheaderTypographyProps={{ color: "initial" }}
          avatar={<MapCardAvatar ariaLabel="info" icon={<i className={faIconClass}></i>} />}
          action={<MapCardHeaderButtonClose onClick={onClose} />}
        />
        <MapCardContent>
          <GridContainer spacing={0}>
            <GridItem xs={12} container>
              {/* <Form dc={dc} mode="view" form={form} record={record}> */}
              <GridContainer spacing={0}>
                <FormContent
                  // dc={dc}
                  // form={form}
                  fields={dc.getFormFields(form)}
                  fieldNames={dc.getFormFieldsNames(form)}
                  columns={1}
                  mode="simpleView"
                  record={record}
                />
              </GridContainer>
              {/* </Form> */}
            </GridItem>
            {/* <GridItem xs={6}>Image Placeholder</GridItem> */}
          </GridContainer>
        </MapCardContent>
        {hasActions ? (
          <MapCardActions>
            {hasPosjedniciButton ? (
              <MapCardActionButton
                startIcon={<AssignmentIndIcon />}
                endIcon={
                  <ExpandMoreIcon
                    sx={[
                      {
                        transform: "rotate(0deg)",
                        transition: (theme) =>
                          theme.transitions.create("all", {
                            duration: theme.transitions.duration.shortest,
                          }),
                      },
                      expandedPosjednici && {
                        transform: "rotate(180deg)",
                      },
                    ]}
                  />
                }
                onClick={handleTogglePosjednici}
                aria-expanded={expandedPosjednici}
                aria-label="expand"
                disabled={recordsPosjednici.length === 0}
              >
                {t("buttons.posjednici")}
              </MapCardActionButton>
            ) : null}

            {hasVlasniciButton ? (
              <MapCardActionButton
                startIcon={<AssignmentIndIcon />}
                endIcon={
                  <ExpandMoreIcon
                    sx={[
                      {
                        transform: "rotate(0deg)",
                        transition: (theme) =>
                          theme.transitions.create("all", {
                            duration: theme.transitions.duration.shortest,
                          }),
                      },
                      expandedVlasnici && {
                        transform: "rotate(180deg)",
                      },
                    ]}
                  />
                }
                onClick={handleToggleVlasnici}
                aria-expanded={expandedVlasnici}
                aria-label="expand"
                disabled={recordsVlasnici.length === 0}
              >
                {t("buttons.vlasnici")}
              </MapCardActionButton>
            ) : null}
          </MapCardActions>
        ) : null}
        {hasPosjedniciButton || hasVlasniciButton ? (
          <Collapse
            in={expandedPosjednici || expandedVlasnici}
            timeout="auto"
            unmountOnExit
            sx={{ maxHeight: 400, overflowX: "scroll", minWidth: 550 }}
          >
            {expandedPosjednici ? (
              <ModelTable
                title=" "
                tableName="posjednici"
                viewName="default"
                records={recordsPosjednici || []}
                dc={dcPosjednici}
                allowSelection="none"
                allowAdd={false}
                allowRowAction={() => {
                  return false;
                }}
                allowRowsPerPageSelection={false}
                onRowAction={() => {}}
                // smallButtons={true}
                disableControls
              />
            ) : null}
            {expandedVlasnici ? (
              <ModelTable
                title=" "
                tableName="vlasnici"
                viewName="default"
                records={recordsVlasnici || []}
                dc={dcVlasnici}
                allowSelection="none"
                allowAdd={false}
                allowRowAction={() => {
                  return false;
                }}
                allowRowsPerPageSelection={false}
                onRowAction={() => {}}
                smallButtons={true}
                disableControls
              />
            ) : null}
          </Collapse>
        ) : null}
      </MapCard>
    </Fragment>
  );
}

export default GSInfoCard;
