import { IModel } from "@/@types/models/model";
import { coreFields } from "./helpers/coreFields";
import { commonFields } from "./helpers/commonFields";

const Kopnena_stanista: IModel = {
  title: "Kopnena stanista",
  apiPath: "data/kopnena_stanista",
  forms: {
    "default": {
        fields: ["fid","nks1","nks1_naziv","nks1_znan","nks2","nks2_naziv","nks2_znan","nks3","nks3_naziv","nks3_znan","area_ha"]
    }
  },
  listViews: {
    "default": {
        fields: ["fid","nks1","nks1_naziv","nks1_znan","nks2","nks2_naziv","nks2_znan","nks3","nks3_naziv","nks3_znan","area_ha"]
    }
  },
  fields: [
    {
        title: "ID",
        source: "fid",
        ttoken: "common.id",
        type: "numeric",
    },
    {
        title: "NKS1 kod",
        source: "nks1",
        ttoken: "stanista.nks1",
        type: "numeric",
    },
    {
        title: "NKS1 naziv",
        source: "nks1_naziv",
        ttoken: "stanista.nks1_naziv",
        type: "text",
    },
    {
        title: "NKS1 znanstveni naziv",
        source: "nks1_znan",
        ttoken: "stanista.nks1_znan",
        type: "text",
    },
    {
        title: "NKS2 kod",
        source: "nks2",
        ttoken: "stanista.nks2",
        type: "numeric",
    },
    {
        title: "NKS2 naziv",
        source: "nks2_naziv",
        ttoken: "stanista.nks2_naziv",
        type: "text",
    },
    {
        title: "NKS2 znanstveni naziv",
        source: "nks2_znan",
        ttoken: "stanista.nks2_znan",
        type: "text",
    },
    {
        title: "NKS3 kod",
        source: "nks3",
        ttoken: "stanista.nks3",
        type: "numeric",
    },
    {
        title: "NKS3 naziv",
        source: "nks3_naziv",
        ttoken: "stanista.nks3_naziv",
        type: "text",
    },
    {
        title: "NKS3 znanstveni naziv",
        source: "nks3_znan",
        ttoken: "stanista.nks3_znan",
        type: "text",
    }, 
    {
        title: "Površina (ha)",
        source: "area_ha",
        ttoken: "common.povrsina_ha",
        type: "numeric",
    },
    

  ]
}
export default Kopnena_stanista;