import MapPage from "@/views/MapPage/MapPage";
import ProfilePage from "@/views/ProfilePage/ProfilePage";
import ThreeDPage from "@/views/ThreeD/ThreeDPage";

//Types
import { RouteType } from "@/@types/common";

const privateRoutes: RouteType[] = [
  { path: "/profile", name: "Profile", component: ProfilePage},
  { path: "/map", name: "Map", component: MapPage },
  // { path: "/3d", name: "3D", component: ThreeDPage },

	{ path: "/", name: "Home", component: MapPage }
];

export default privateRoutes
